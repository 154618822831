import "./styles/Work.css";
import { useEffect, useState } from "react";
import Footer from "./footer/Footer";

import BrandLogo from "./components/works/BrandLogo";
import PhotographyPdf from "./components/works/PhotographyPdf";

function DeskWork() {
  const [select, setSelected] = useState("0");
  const activate = (index) => {
    let toggler = document.querySelectorAll(".link");
    toggler[index].classList.toggle("link__active");
    for (let i = 0; i < 4; i++) {
      if (i === index) continue;
      toggler[i].classList.remove("link__active");
    }
  };
  useEffect(() => {
    activate(0);
  }, []);
  return (
    <>
      <div className="work__box">
        <div className="heading">
          <h1 className="menu_heading">OUR WORK</h1>
        </div>
        {/* <div className="back__logo">
            <NavLink to="/">
              <i class="fas fa-3x fa-arrow-circle-right"></i>
            </NavLink>
          </div> */}
        <div className="container-fluid">
          <div className="row">
            <div className="col-4 col-lg-3 work__left">
              <h1
                onClick={() => {
                  setSelected("0");
                  activate(0);
                }}
              >
                <span className="link">BRAND IDENTITY</span>
              </h1>
              <h1
                onClick={() => {
                  setSelected("1");
                  activate(1);
                }}
              >
                <span className="link">PHOTOGRAPHY</span>
              </h1>
              <h1
                onClick={() => {
                  setSelected("2");
                  activate(2);
                }}
              >
                <span className="link">VIDEOGRAPHY</span>
              </h1>
              <h1
                onClick={() => {
                  setSelected("3");
                  activate(3);
                }}
              >
                <span className="link">SOCIAL MEDIA</span>
              </h1>
            </div>
            <div className="col-8 col-lg-9 work__right">
              {select === "0" && <BrandLogo />}
              {select === "1" && <PhotographyPdf />}
              {select === "2" && <h1>Coming Soon...</h1>}
              {select === "3" && <h1>Coming Soon...</h1>}
            </div>
          </div>
        </div>
      </div>
      {/* Bottom Bar */}
      <Footer closeTab="work" padding="pr-5" />
    </>
  );
}

export default DeskWork;
