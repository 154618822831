import mbServices from "./mobile/mbServices";
import DeskServices from "./desktop/deskServices";

function Services() {
  return (
    <>
      <div className="desktop">{DeskServices()}</div>
      <div className="mobile">{mbServices()}</div>
    </>
  );
}

export default Services;
