import we from "../../../data/about/we";

const We = () => {
  return (
    <>
      <div>
        {we.map((item, key) => {
          return <p key={key}>{item}</p>;
        })}
      </div>
    </>
  );
};

export default We;
