import "./styles/mbAbout.css";
import We from "./components/about/We";
import Team from "./components/about/Team";
import Clientel from "./components/about/Clientel";
import TermsAndConditions from "../mobile/components/about/TermsAndConditions";
import { useState } from "react";
import { NavLink } from "react-router-dom";
import backLogo from "../../pages/assets/cross.svg";

function MbAbout() {
  const [select, setSelected] = useState("0");
  const activeStyle = {
    border: "2px solid white",
    backgroundColor: "black",
    color: "white",
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          animation: "animate__about ease-in-out 0.5s",
        }}
      >
        <h1 className="about__title">ABOUT US</h1>
        <NavLink className="crossImg" to="/">
          <img
            style={{ height: "20px", marginRight: "2rem" }}
            src={backLogo}
            alt=""
          />
        </NavLink>
      </div>
      <div className="about__page__mb">
        <div className="about__buttons">
          <button
            style={select === "0" ? activeStyle : {}}
            className="mb__button__ab"
            onClick={() => setSelected("0")}
          >
            WHO ARE WE?
          </button>
          <button
            style={select === "1" ? activeStyle : {}}
            className="mb__button__ab"
            onClick={() => setSelected("1")}
          >
            TEAM
          </button>
          <button
            style={select === "2" ? activeStyle : {}}
            className="mb__button__ab"
            onClick={() => setSelected("2")}
          >
            CLIENTELE
          </button>
          <button
            style={select === "3" ? activeStyle : {}}
            className="mb__button__ab"
            onClick={() => setSelected("3")}
          >
            Terms And Conditions
          </button>
        </div>

        <div className="about__content">
          {select === "0" && <We />}
          {select === "1" && <Team />}
          {select === "2" && <Clientel />}
          {select === "3" && <TermsAndConditions />}
        </div>
      </div>
    </>
  );
}

export default MbAbout;
