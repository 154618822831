import graphicSocialPDF from "../../assets/branding/The Graphic Social.pdf";
import gizmosityPDF from "../../assets/branding/Gizmosity.pdf";
import glowPDF from "../../assets/branding/glow.pdf";
import uphaarPDF from "../../assets/branding/Uphaar.pdf";
import norbuPDF from "../../assets/branding/NorbuHomes.pdf";

import graphicSocialTB from "../../assets/branding/thumbnails/TheGraphicSocial.png";
import gizmosityTB from "../../assets/branding/thumbnails/Gizmosity.png";
import glowTB from "../../assets/branding/thumbnails/glow.png";
import uphaarTB from "../../assets/branding/thumbnails/Uphaar.png";
import norbuTB from "../../assets/branding/thumbnails/norbuHome.png";

const brandIdentity = [
  {
    tb: norbuTB,
    pdf: norbuPDF,
  },
  {
    tb: graphicSocialTB,
    pdf: graphicSocialPDF,
  },
  {
    tb: gizmosityTB,
    pdf: gizmosityPDF,
  },
  {
    tb: glowTB,
    pdf: glowPDF,
  },
  {
    tb: uphaarTB,
    pdf: uphaarPDF,
  },
];

export default brandIdentity;
