import Card from "../../../../desktop/components/works/Card.js";
import photography from "../../../../data/work/photography.js";
function PhotographyPdf() {
  return (
    <>
      <div className="row">
        {photography.map((item, key) => {
          return (
            <div className="col-lg-6">
              <Card pic={item.tb} pdf={item.pdf} />
            </div>
          );
        })}
      </div>
    </>
  );
}

export default PhotographyPdf;
