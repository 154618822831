const socialMedia = {
  title: "Social Media",
  desc: "Social media is today’s newspaper for both the young and the old. Everybody is hooked on their devices the first thing in the morning. That means your all the world’s attention is there, spread across a handful of platforms i.e. Instagram, Facebook and Twitter. How can we help you?",
  list: [
    "We will strategise what goes on your social media",
    "Design your posts",
    "Organise photo and video shoots if that’s what your brand needs for the content",
    "Manage all your social media handles including posting on scheduled times, interacting with your audience and getting them to know your brand better",
    "Plan everything ahead of time so you don’t have to worry about what is it that you need to post",
    "Research the market and hop on the trends as they come to maximise your brand’s reach",
  ],
};

export default socialMedia;
