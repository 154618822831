import "./styles/mbHome.css";
import Logo from "../assets/mbLogo.png";
import { NavLink } from "react-router-dom";

function mbHome() {
  const { innerWidth, innerHeight } = window;

  return (
    <>
      <div className="mbHome__box">
        <NavLink
          style={{
            position: "absolute",
            transform: "rotate(180deg)",
            left: "0",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            writingMode: "vertical-lr",
            minHeight: innerHeight / 2,
          }}
          to="/contact"
        >
          <h1 style={{ marginRight: "0.3rem" }} className="home_links">
            CONTACT US
          </h1>
        </NavLink>
        <NavLink
          style={{
            position: "absolute",
            transform: "rotate(180deg)",
            right: "0",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            writingMode: "vertical-lr",
            minHeight: innerHeight / 2,
          }}
          to="/about"
        >
          <h1 style={{ marginLeft: "0.3rem" }} className="home_links">
            ABOUT US
          </h1>
        </NavLink>
        <NavLink
          style={{
            color: "white",
            position: "absolute",
            transform: "rotate(180deg)",
            bottom: "0",
            left: "0",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            writingMode: "vertical-lr",
            minHeight: innerHeight / 2,
          }}
          to="/services"
        >
          <h1 style={{ marginRight: "0.3rem" }} className="home_links">
            SERVICES
          </h1>
        </NavLink>
        <NavLink
          style={{
            color: "white",
            position: "absolute",
            transform: "rotate(180deg)",
            bottom: "0",
            right: "0",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            writingMode: "vertical-lr",
            minHeight: innerHeight / 2,
          }}
          to="/work"
        >
          <h1 style={{ marginLeft: "0.3rem" }} className="home_links">
            OUR WORK
          </h1>
        </NavLink>

        <div className="mbHome__content">
          <img
            class="img-fluid"
            style={{
              width: innerWidth * 0.45,
            }}
            src={Logo}
            alt=""
          />
        </div>
      </div>
    </>
  );
}

export default mbHome;
