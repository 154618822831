import "./styles/Base.css";

function Branding({ para, showPdf }) {
  return (
    <div className="mb__brand_box">
      <div className="mb__box">
        <p className="box__head">{para}</p>
      </div>
      <div>{showPdf}</div>
    </div>
  );
}
export default Branding;
