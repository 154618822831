import React from "react";
import socialMedia from "../../../data/services/socialMedia.js";

function SocialMedia() {
  return (
    <>
      <h1>{socialMedia.title}</h1>
      <p>{socialMedia.desc}</p>
      <ul>
        {socialMedia.list.map((item, key) => (
          <li key={key}>{item}</li>
        ))}
      </ul>
    </>
  );
}

export default SocialMedia;
