import "./styles/mbServices.css";
import { useState } from "react";
import Base from "./components/services/Base";
import { NavLink } from "react-router-dom";
import backLogo from "../../pages/assets/cross.svg";

import branding from "../data/services/branding";
import digitalMarketing from "../data/services/digitalMarketing";
import socialMedia from "../data/services/socialMedia";
import photoAndVideo from "../data/services/photoAndVideo";

function MbServices() {
  const [select, setSelected] = useState("");
  const goBack = () => {
    setSelected("");
  };
  const headingMargin = { marginBottom: "1.25rem" };
  return (
    <>
      <div style={{ animation: "animate__service ease-in-out 0.5s" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <h1 className="s_title">SERVICES</h1>
          <NavLink className="crossImg" to="/">
            <img
              style={{ height: "20px", marginRight: "2rem" }}
              src={backLogo}
              alt=""
            />
          </NavLink>
        </div>
        <div className="menu">
          <div className="s_headings">
            <h1
              style={select === "0" ? headingMargin : {}}
              className="service__heads"
              onClick={() => {
                select === "0" ? goBack() : setSelected("0");
              }}
            >
              BRAND IDENTITY
            </h1>
            {select === "0" && (
              <Base content={branding.desc} list={branding.list} />
            )}
            <h1
              className="service__heads"
              style={select === "1" ? headingMargin : {}}
              onClick={() => {
                select === "1" ? goBack() : setSelected("1");
              }}
            >
              SOCIAL MARKETING
            </h1>
            {select === "1" && (
              <Base content={socialMedia.desc} list={socialMedia.list} />
            )}

            <h1
              className="service__heads"
              style={select === "2" ? headingMargin : {}}
              onClick={() => {
                select === "2" ? goBack() : setSelected("2");
              }}
            >
              PHOTOGRAPHY
            </h1>
            {select === "2" && (
              <Base content={photoAndVideo.desc} list={photoAndVideo.list} />
            )}

            <h1
              className="service__heads"
              style={select === "3" ? headingMargin : {}}
              onClick={() => {
                select === "3" ? goBack() : setSelected("3");
              }}
            >
              DIGITAL MARKETING
            </h1>
            {select === "3" && (
              <Base
                content={digitalMarketing.desc}
                list={digitalMarketing.list}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default MbServices;
