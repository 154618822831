import "./styles/Home.css";
import { NavLink } from "react-router-dom";
import SocialLeft from "../assets/SocialLeft.svg";
import SocialRight from "../assets/SocialRight.svg";

function DeskHome() {
  const { innerWidth, innerHeight } = window;
  return (
    <>
      <div className="social__box">
        <div className="social__content">
          <embed
            style={{
              position: "absolute",
              height: innerHeight * 0.15,
              right: innerWidth / 2,
            }}
            src={SocialLeft}
            alt="Social logo"
          />
          <embed
            style={{
              position: "absolute",
              height: innerHeight * 0.15,
              left: innerWidth / 2,
            }}
            src={SocialRight}
            alt="Social logo"
          />
        </div>
        <div className="tabs">
          <NavLink to="/services">
            <h1 className="home_links">SERVICES</h1>
          </NavLink>
          <NavLink to="/work">
            <h1 className="home_links">OUR WORK</h1>
          </NavLink>
          <NavLink style={{ color: "black" }} to="/contact">
            <h1 className="home_links">CONTACT US</h1>
          </NavLink>
          <NavLink style={{ color: "black" }} to="/about">
            <h1 className="home_links">ABOUT US</h1>
          </NavLink>
        </div>
      </div>
    </>
  );
}

export default DeskHome;
