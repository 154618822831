import React from "react";
import branding from "../../../data/services/branding";

function Branding() {
  return (
    <>
      <h1>{branding.title}</h1>
      <p>{branding.desc}</p>
      <ul>
        {branding.list.map((item, key) => (
          <li key={key}>{item}</li>
        ))}
      </ul>
    </>
  );
}

export default Branding;
