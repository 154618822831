import React from "react";
import Close from "./Close";
import BarLink from "./BarLink";

function Footer({ closeTab, padding, rightPadding }) {
  // style={{ animation: "transitionUp 0.9s" } }
  return (
    <>
      <div
        className="tabs"
        style={{
          paddingRight: rightPadding,
        }}
      >
        {closeTab !== "services" ? (
          <BarLink link="/services" name="SERVICES" />
        ) : (
          <Close padding={padding} />
        )}
        {closeTab !== "work" ? (
          <BarLink link="/work" name="OUR WORK" />
        ) : (
          <Close padding={padding} />
        )}
        {closeTab !== "contact" ? (
          <BarLink link="/contact" name="CONTACT US" />
        ) : (
          <Close padding={padding} />
        )}
        {closeTab !== "about" ? (
          <BarLink link="/about" name="ABOUT US" />
        ) : (
          <Close padding={padding} />
        )}
        {/* <NavLink to="/services" className="home_links">
          SERVICES
        </NavLink>
        <div className="d-flex justify-content-center">
          <NavLink to="/" className="text-light">
            <i className="pr-5 fas fa-2x fa-times"></i>
          </NavLink>
        </div>
        <NavLink to="/contact" className="home_links">
          CONTACT US
        </NavLink>
        <NavLink to="/about" className="home_links">
          ABOUT US
        </NavLink> */}
      </div>
    </>
  );
}

export default Footer;
