import React from "react";
import team from "../../../data/about/team";
import "./styles/Team.css";

function Photos({ name = "name", designation = "designation", pic = test }) {
  return (
    <>
      {/* <div className="Photos__mb col-md-3">
        <div className="row justify-content-center">
          <img className="Photos__content__mb img-fluid" src={test} alt="" />
          <h5 className="text-center">name1</h5>
        </div>
      </div> f */}
      <div className="Photos__mb">
        <div className="justify-content-center">
          <div className="d-flex justify-content-center">
            <img className="Photos__content__mb img-fluid" src={pic} alt="" />
          </div>
          <div className="mt-1">
            <h5
              style={{ fontSize: "1.25rem", fontWeight: "900a" }}
              className="Photos__title__mb"
            >
              {name}
            </h5>
            <p
              style={{
                textAlign: "center",
                fontSize: "1rem",
                fontWeight: "500",
              }}
            >
              {designation}
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

function Team() {
  return (
    <>
      <div>
        <h4 style={{ marginTop: "0.5rem", fontWeight: "600" }}>OUR TEAM</h4>
        <div className="team__container__mb animation_box">
          {team.map((item, key) => {
            return (
              <Photos
                key={key}
                name={item.name}
                designation={item.designation}
                pic={item.pic}
              />
            );
          })}{" "}
        </div>
      </div>
    </>
  );
}

export default Team;
