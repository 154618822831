const branding = {
  title: "Branding",
  desc: "Branding is something that defines your brand and lets your customers realise what you stand for. This doesn’t just include making Logos, this involves making a strategy about what to say and how to say it to your audience so they not only can see what you’re offering but why you’re doing what you’re doing. This involves:",
  list: [
    "Logo",
    "Packings",
    "Brand Strategy -- How to strategise your brand to strengthen the vision behind your brand in eyes of your audience",
    "Messaging -- What you say to your audience across different platforms",
    "Brand Kit",
    "Market Analysis and Evaluation",
  ],
};

export default branding;
