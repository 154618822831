import React, { useState } from "react";
import Footer from "./footer/Footer.js";

import Clientel from "./components/about/Clientel.js";
import TermsAndConditions from "./components/about/TermsAndConditions.js";
import Team from "./components/about/Team.js";
import We from "./components/about/We.js";
import "./styles/About.css";

function DeskAbout() {
  const [select, setSelected] = useState("0");
  const activeStyle = {
    border: "2px solid white",
    backgroundColor: "black",
    color: "white",
  };
  return (
    <>
      {/* lower shit show */}
      <div className="about__box">
        <div className="heading">
          <h1 className="menu_heading">ABOUT US</h1>
        </div>
        {/* <div className="back__logo">
            <NavLink to="/">
              <i className="fab fa-3x fa-slack"></i>
            </NavLink>
          </div> */}
        <div className="container-fluid">
          <div className="row">
            <div className="order-2 col-7 col-lg-9 about__left">
              {select === "0" && (
                <div className="content__active">
                  <We />
                </div>
              )}
              {select === "1" && (
                <div className="content__active">
                  <Team />
                </div>
              )}
              {select === "2" && (
                <div className="content__active">
                  <Clientel />
                </div>
              )}
              {select === "3" && (
                <div className="content__active">
                  <TermsAndConditions />
                </div>
              )}
            </div>

            <div className="order-1 col-5 col-lg-3 d-flex flex-column  about__right">
              <button
                style={select === "0" ? activeStyle : {}}
                className="button"
                onClick={() => {
                  setSelected("0");
                }}
              >
                <span>WHO ARE WE?</span>
              </button>

              <button
                style={select === "1" ? activeStyle : {}}
                className="button"
                onClick={() => {
                  setSelected("1");
                }}
              >
                <span>OUR TEAM</span>
              </button>

              <button
                style={select === "2" ? activeStyle : {}}
                className="button"
                onClick={() => {
                  setSelected("2");
                }}
              >
                <span>CLIENTLE</span>
              </button>
              <button
                style={select === "3" ? activeStyle : {}}
                className="button"
                onClick={() => {
                  setSelected("3");
                }}
              >
                <span>Terms And Conditions</span>
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* Bottom Bar */}
      <Footer closeTab="about" padding="pr-5" />
    </>
  );
}

export default DeskAbout;
