const photoAndVideo = {
  title: "Photo and Video",
  desc: "Photography is an art of capturing the what we see on to a film/ digital reel. It’s not just pointing the camera at something and pressing that button. It takes years to learn and even more to get great at it and we have been doing it for even more. We mainly excel in Commercial Photo and Video shoots so we can show your customers what your brand stands for. What’s that story behind every product of your brand that makes it stand out in the market, so they’re not just buying your products but their stories along with it. Here are things that we do:",
  list: [
    "Campaign Shoots",
    "Organising the campaigns",
    "Concept shoots",
    "E-Commerce Photoshoots",
    "Music Videos",
    "Reels",
  ],
};

export default photoAndVideo;
