import React, { useState } from "react";
import Footer from "./footer/Footer";

import "./styles/Contact.css";

function DeskContact() {
  const buttonToggle = () => {
    const toggler = document.querySelector(".button__toggle");
    toggler.classList.toggle("activate");
    //Radio button off property
    const other1 = document.querySelector(".schedule__form");
    const other2 = document.querySelector(".getLoc");
    other1.classList.remove("activate");
    other2.classList.remove("activate");
  };
  const schedule = () => {
    const toggler = document.querySelector(".schedule__form");
    toggler.classList.toggle("activate");
    //Radio button off property
    const other1 = document.querySelector(".button__toggle");
    const other2 = document.querySelector(".getLoc");
    other1.classList.remove("activate");
    other2.classList.remove("activate");
  };
  const getLocation = () => {
    const toggler = document.querySelector(".getLoc");
    toggler.classList.toggle("activate");
    //Radio button off property
    const other1 = document.querySelector(".button__toggle");
    const other2 = document.querySelector(".schedule__form");
    other1.classList.remove("activate");
    other2.classList.remove("activate");
  };
  const [select, setSelected] = useState(null);
  const activeStyle = {
    border: "2px solid white",
    backgroundColor: "black",
    color: "white",
  };
  return (
    <>
      {/* lower shit show */}
      <div className="contact__box">
        <div className="heading">
          <h1 className="menu_heading">CONTACT US</h1>
        </div>
        {/* <div className="back__logo">
            <NavLink to="/">
              <i class="fas fa-3x fa-arrow-right"></i>
            </NavLink>
          </div> */}
        <div className="container-fluid">
          <div className="row">
            <div className="order-2  col-8 col-lg-9 contact__left">
              <div className="contact__left__box">
                <p>Address</p>
                <a
                  target="__blank"
                  href="https://www.google.com/maps/place/Bala+Ji+Refrigeration+Works/@31.621764,74.866744,19z/data=!4m5!3m4!1s0x0:0xcfceb25f0cf9e679!8m2!3d31.6217637!4d74.8667439?hl=en"
                >
                  Amritsar, Punjab, IN
                </a>
                <p>Phone</p>
                <a href="tel:+919501446674">+91-95014-46674</a>
                <p>Mail</p>
                <a href="mailto:maheep@thegraphicsocial.com">
                  maheep@thegraphicsocial.com
                </a>
              </div>
            </div>
            <div className="order-1 col-4 col-lg-3 d-flex flex-column  contact__right">
              <button
                style={select === "0" ? activeStyle : {}}
                className="button"
                onClick={() => {
                  buttonToggle();
                  setSelected("0");
                }}
              >
                ENQUIRE NOW
              </button>
              <div className="button__toggle">
                <a href="tel:+919501446674">
                  <i className="fas fa-2x fa-phone"></i>
                </a>
                <a href="mailto:maheep@thegraphicsocial.com">
                  <i className="far fa-2x fa-envelope"></i>
                </a>
                <a href="https://wa.me/+919501446674">
                  <i className="fab fa-2x fa-whatsapp"></i>
                </a>
              </div>
              <button
                className="button"
                style={select === "1" ? activeStyle : {}}
                onClick={() => {
                  schedule();
                  setSelected("1");
                }}
              >
                SCHEDULE A CALL
              </button>
              <form action="" className="schedule__form">
                <input id="fname" type="text" placeholder="First Name" />
                <input id="lname" type="text" placeholder="Last Name" />
                <input id="meeting" type="date" />
                <input id="meeting" type="time" />
                <button className="btn btn-outline-light" type="submit">
                  Submit
                </button>
              </form>
              <button
                style={select === "2" ? activeStyle : {}}
                onClick={() => {
                  getLocation();
                  setSelected("2");
                }}
                className="button"
              >
                GET DIRECTIONS
              </button>
              <iframe
                title="map"
                className="getLoc"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d212.3415232102146!2d74.85940156127025!3d31.621133611054443!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39197b53dfebf8b5%3A0xcfceb25f0cf9e679!2sBala%20Ji%20Refrigeration%20Works!5e0!3m2!1sen!2sin!4v1637601579274!5m2!1sen!2sin"
                loading="lazy"
              ></iframe>
            </div>
          </div>
        </div>
      </div>
      {/* Bottom Bar */}
      <Footer closeTab="contact" padding="pr-5" />
    </>
  );
}

export default DeskContact;
