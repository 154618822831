import we from "../../../data/about/we";

const We = () => {
  return (
    <>
      <div
        style={{
          fontSize: "1.5rem",
          fontWeight: "600",
        }}
        className="animation_box"
      >
        {we.map((item, key) => {
          return <p key={key}>{item}</p>;
        })}
      </div>
    </>
  );
};

export default We;
