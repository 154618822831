import React from "react";
import { NavLink } from "react-router-dom";

function Close({ padding }) {
  return (
    <div className="d-flex justify-content-center">
      <NavLink to="/" className="text-light">
        <i className={padding + " fas fa-2x fa-times"}></i>
      </NavLink>
    </div>
  );
}

export default Close;
