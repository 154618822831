import React from "react";

import MbAbout from "./mobile/mbAbout.js";

import DeskAbout from "./desktop/deskAbout.js";

function About() {
  return (
    <>
      <div className="desktop">{DeskAbout()}</div>
      <div className="mobile">{MbAbout()}</div>
    </>
  );
}

export default About;
