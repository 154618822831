import "./styles/Base.css";
function Base({ content, list }) {
  return (
    <>
      <div className="brand__box">
        <div className="s_brand">
          <p>{content}</p>
          <ul>
            {list.map((item, key) => {
              return <li key={key}>{item}</li>;
            })}
          </ul>
        </div>
      </div>
    </>
  );
}

export default Base;
