import "./App.css";
import { Switch, Route } from "react-router-dom";
import Home from "./pages/Home.js";
import Services from "./pages/Services";
import Contact from "./pages/Contact";
import Work from "./pages/Work";
import About from "./pages/About";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

function App() {
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/services" component={Services} />
        <Route exact path="/contact" component={Contact} />
        <Route exact path="/work" component={Work} />
        <Route exact path="/about" component={About} />
      </Switch>
    </MuiPickersUtilsProvider>
  );
}

export default App;
