import terms from "../../../data/about/terms";

const TermsAndConditions = () => {
  return (
    <>
      <div>
        {terms.map((item, key) => {
          return <p key={key}>{item}</p>;
        })}
      </div>
    </>
  );
};

export default TermsAndConditions;
