import React from "react";
import digitalMarketing from "../../../data/services/digitalMarketing";

function DigitalMarketing() {
  return (
    <>
      <h1>{digitalMarketing.title}</h1>
      <p>{digitalMarketing.desc}</p>
      <ul>
        {digitalMarketing.list.map((item, key) => (
          <li key={key}>{item}</li>
        ))}
      </ul>
    </>
  );
}

export default DigitalMarketing;
