import clientle from "../../../data/about/clientle";
import "./styles/Clientel.css";

function Clientel() {
  return (
    <>
      <div className="">
        <div className="row">
          {clientle.map((item, key) => {
            return (
              <div key={key} className="client col-6 col-lg-4">
                <embed
                  style={{ height: "7rem" }}
                  className="img-fluid"
                  src={item}
                  alt=""
                />
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
}

export default Clientel;
