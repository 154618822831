const digitalMarketing = {
  title: "Digital Marketing",
  desc: "Digital Marketing is nothing but marketing on the digital front. This involves everything that you see around a brand on the internet. We reach out to your audience on all fronts on the internet. From their social media feeds, videos they watch on YouTube, Pages they like on Facebook. Digital marketing doesn’t just involve social media, it’s a lot more.",
  list: [
    "We get your website up and running",
    "Strategise and work towards your social media",
    "Advertisements that go on the Social Media platforms including Instagram, Facebook, YouTube, Google, Pinterest, LinkedIn, Quora etc.",
    "Strategizing and planning promotional campaigns to bring more awareness to the brand",
  ],
};

export default digitalMarketing;
