import React from "react";
import { NavLink } from "react-router-dom";

function BarLink({ link, name }) {
  return (
    <NavLink to={link} className="home_links">
      {name}
    </NavLink>
  );
}

export default BarLink;
