import DeskHome from "./desktop/deskHome";
import mbHome from "./mobile/mbHome.js";
function Home() {
  return (
    <>
      <div className="desktop">{DeskHome()}</div>
      <div className="mobile">{mbHome()}</div>
    </>
  );
}

export default Home;
