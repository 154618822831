import "./styles/Services.css";
import { useEffect, useState } from "react";
import Footer from "./footer/Footer";

import Branding from "./components/services/Branding";
import DigitalMarketing from "./components/services/DigitalMarketing";
import SocialMedia from "./components/services/SocialMedia";
import PhotoVideo from "./components/services/PhotoVideo";

function DeskServices() {
  const [select, setSelected] = useState("0");
  const activate = (index) => {
    let toggler = document.querySelectorAll(".link");
    toggler[index].classList.toggle("link__active");
    for (let i = 0; i < 4; i++) {
      if (i === index) continue;
      toggler[i].classList.remove("link__active");
    }
  };
  useEffect(() => {
    activate(0);
  }, []);
  return (
    <>
      <div className="services__box">
        <div className="heading">
          <h1 className="menu_heading">SERVICES</h1>
        </div>
        {/* <div className="back__logo">
            <NavLink to="/">
              <i class="fas fa-3x fa-anchor"></i>
            </NavLink>
          </div> */}
        <div className="container-fluid">
          <div className="row">
            <div className="col-4 col-lg-3 services__left">
              <h1
                onClick={() => {
                  setSelected("0");
                  activate(0);
                }}
              >
                <span className="link">BRAND IDENTITY</span>
              </h1>
              <h1
                onClick={() => {
                  setSelected("1");
                  activate(1);
                }}
              >
                <span className="link">DIGITAL MARKETING</span>
              </h1>
              <h1
                onClick={() => {
                  setSelected("2");
                  activate(2);
                }}
              >
                <span className="link">SOCIAL MEDIA</span>
              </h1>
              <h1
                onClick={() => {
                  setSelected("3");
                  activate(3);
                }}
              >
                <span className="link">PHOTOGRAPHY</span>
              </h1>
            </div>
            <div className="col-8 col-lg-9 services__right">
              {select === "0" && <Branding />}
              {select === "1" && <DigitalMarketing />}
              {select === "2" && <SocialMedia />}
              {select === "3" && <PhotoVideo />}
            </div>
          </div>
        </div>
      </div>
      {/* Bottom Bar */}
      <Footer closeTab="services" padding="pl-5" />
    </>
  );
}

export default DeskServices;
