import React from "react";
import photoAndVideo from "../../../data/services/photoAndVideo";

function PhotoVideo() {
  return (
    <>
      <h1>{photoAndVideo.title}</h1>
      <p>{photoAndVideo.desc}</p>
      <ul>
        {photoAndVideo.list.map((item, key) => (
          <li key={key}>{item}</li>
        ))}
      </ul>
    </>
  );
}

export default PhotoVideo;
