import umang from "../../assets/team/umang.png";
import kabir from "../../assets/team/kabir.png";
import maheep from "../../assets/team/maheep.png";

const team = [
  {
    name: "Umang Raghuvanshi",
    designation: "Chief Video Editor",
    pic: umang,
  },
  {
    name: "Kabir Singh",
    designation: "Chief Designer",
    pic: kabir,
  },
  {
    name: "Maheep Chhabra",
    designation: "Founder",
    pic: maheep,
  },
];

export default team;
