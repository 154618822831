import MbWork from "./mobile/mbWork";
import DeskWork from "./desktop/deskWork";

function Work() {
  return (
    <>
      <div className="desktop">{DeskWork()}</div>
      <div className="mobile">{MbWork()}</div>
    </>
  );
}

export default Work;
