import Card from "../../../../desktop/components/works/Card.js";
import brandIdentity from "../../../../data/work/brandIdentity.js";

function BrandLogo() {
  return (
    <>
      <div className="row">
        {brandIdentity.map((item, key) => {
          return (
            <div key={key} className="col-lg-6">
              <Card pic={item.tb} pdf={item.pdf} />
            </div>
          );
        })}
      </div>
    </>
  );
}

export default BrandLogo;
