import "./styles/Clientel.css";
import clientle from "../../../data/about/clientle";

function Clientel() {
  return (
    <>
      <div className="animation_box">
        <h4 style={{ marginTop: "0.5rem", fontWeight: "600" }}>
          OUR CLIENTELE
        </h4>
        <div className="team__container__mb client__content">
          {clientle.map((item, key) => {
            return <embed className="img-fluid" src={item} alt="" />;
          })}
        </div>
      </div>
    </>
  );
}

export default Clientel;
