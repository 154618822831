import React from "react";

import MbContact from "./mobile/mbContact";
import DeskContact from "./desktop/deskContact";

function Contact() {
  return (
    <>
      <div className="desktop">{DeskContact()}</div>
      <div className="mobile">{MbContact()}</div>
    </>
  );
}

export default Contact;
