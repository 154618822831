import "./styles/mbServices.css";
import { useState } from "react";
import Base from "./components/work/Base.js";
import { NavLink } from "react-router-dom";
import backLogo from "../../pages/assets/cross.svg";
// Pdf's Components
import BrandLogo from "./components/work/helper/BrandLogo";
import PhotographyPdf from "./components/work/helper/PhotographyPdf";

function MbWork() {
  const [clicked, setClicked] = useState("");
  const goBack = () => {
    setClicked("");
  };

  return (
    <>
      <div style={{ animation: "animate__work ease-in-out 0.5s" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <h1 className="s_title">OUR WORK</h1>
          <NavLink className="crossImg" to="/">
            <img
              style={{ height: "20px", marginRight: "2rem" }}
              src={backLogo}
              alt=""
            />
          </NavLink>
        </div>
        <div className="menu">
          <div className="s_headings">
            <h1
              className="work__head"
              onClick={() => {
                clicked === "0" ? goBack() : setClicked("0");
              }}
            >
              BRAND IDENTITY
            </h1>
            {clicked === "0" && <Base showPdf={<BrandLogo />} />}
            <h1
              className="work__head"
              onClick={() => {
                clicked === "1" ? goBack() : setClicked("1");
              }}
            >
              PHOTOGRAPHY
            </h1>
            {clicked === "1" && <Base showPdf={<PhotographyPdf />} />}

            <h1
              className="work__head"
              onClick={() => {
                clicked === "2" ? goBack() : setClicked("2");
              }}
            >
              VIDEOGRAPHY
            </h1>
            {clicked === "2" && <Base para="Videography Coming soon..." />}
            <h1
              className="work__head"
              onClick={() => {
                clicked === "3" ? goBack() : setClicked("3");
              }}
            >
              SOCIAL MEDIA
            </h1>
            {clicked === "3" && <Base para="Social Media Coming soon.." />}
          </div>
        </div>
      </div>
    </>
  );
}

export default MbWork;
