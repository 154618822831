import hirect from "../../assets/clientel/hirect.svg";
import meesho from "../../assets/clientel/meesho.svg";
import Moj from "../../assets/clientel/Moj.svg";
import buddweiser from "../../assets/clientel/buddweiser.svg";
import redbull from "../../assets/clientel/redbull.svg";
import Nissin from "../../assets/clientel/Nissin.svg";

const clientle = [hirect, meesho, Moj, buddweiser, redbull, Nissin];

export default clientle;
