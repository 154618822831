import ARSHAGROVERMAKEUP_PDF from "../../assets/photography/ARSHAGROVERMAKEUP.pdf";
import POP_CULTURE_PDF from "../../assets/photography/POP_CULTURE.pdf";
import PREWEDDING_PDF from "../../assets/photography/PREWEDDING_compressed.pdf";
import shahzadi_PDF from "../../assets/photography/shahzadi._compressed.pdf";
import ShalePashm_PDF from "../../assets/photography/Shal-e-Pashm_compressed.pdf";
import TAANI_STORE_PDF from "../../assets/photography/TAANI_STORE._compressed.pdf";
import THE_MODERN_DAY_QUEEN_PDF from "../../assets/photography/THE_MODERN_DAY_QUEEN_compressed.pdf";

import ARSHAGROVERMAKEUP_TB from "../../assets/photography/thumbnails/ARSHAGROVERMAKEUP.png";
import POP_CULTURE_TB from "../../assets/photography/thumbnails/POP_CULTURE.png";
import PREWEDDING_TB from "../../assets/photography/thumbnails/PREWEDDING_compressed.png";
import shahzadi_TB from "../../assets/photography/thumbnails/shahzadi._compressed.png";
import ShalePashm_TB from "../../assets/photography/thumbnails/Shal-e-Pashm_compressed.png";
import TAANI_STORE_TB from "../../assets/photography/thumbnails/TAANI_STORE._compressed.png";
import THE_MODERN_DAY_QUEEN_TB from "../../assets/photography/thumbnails/THE_MODERN_DAY_QUEEN_compressed.pdf.png";

const photography = [
  {
    tb: ARSHAGROVERMAKEUP_TB,
    pdf: ARSHAGROVERMAKEUP_PDF,
  },
  {
    tb: POP_CULTURE_TB,
    pdf: POP_CULTURE_PDF,
  },
  {
    tb: PREWEDDING_TB,
    pdf: PREWEDDING_PDF,
  },
  {
    tb: shahzadi_TB,
    pdf: shahzadi_PDF,
  },
  {
    tb: ShalePashm_TB,
    pdf: ShalePashm_PDF,
  },
  {
    tb: TAANI_STORE_TB,
    pdf: TAANI_STORE_PDF,
  },
  {
    tb: THE_MODERN_DAY_QUEEN_TB,
    pdf: THE_MODERN_DAY_QUEEN_PDF,
  },
];

export default photography;
