import React from "react";
import team from "../../../data/about/team";

function Photos({ name = "name", designation = "designation", pic = test }) {
  return (
    <>
      <div className="col-lg-4 col-6">
        <div className="d-flex justify-content-center">
          <img
            style={{
              height: "400px",
            }}
            className="img-fluid"
            src={pic}
            alt=""
          />
        </div>

        <div className="mt-2">
          <h5 style={{ fontWeight: "900" }} className="text-center">
            {name}
          </h5>
          <p
            style={{ textAlign: "center", fontSize: "1rem", fontWeight: "500" }}
          >
            {designation}
          </p>
        </div>
      </div>
    </>
  );
}

function Team() {
  return (
    <>
      <div className="row">
        {team.map((item, key) => {
          return (
            <Photos
              key={key}
              name={item.name}
              designation={item.designation}
              pic={item.pic}
            />
          );
        })}
      </div>
    </>
  );
}

export default Team;
