import { useState } from "react";
import "./styles/mbContact.css";
import { NavLink } from "react-router-dom";
import backLogo from "../../pages/assets/cross.svg";
import { ThemeProvider } from "@material-ui/styles";
import { DateTimePicker } from "@material-ui/pickers";
import { createMuiTheme } from "@material-ui/core";
import black from "@material-ui/core/colors/black";

const defaultMaterialTheme = createMuiTheme({
  palette: {
    primary: black,
  },
});

function MbContact() {
  const [select, setSelected] = useState("");
  const [date, setDate] = useState(new Date());
  const activeStyle = {
    border: "2px solid white",
    backgroundColor: "black",
    color: "white",
  };
  const buttonToggle = () => {
    const toggler = document.querySelector(".mb__button__toggle");
    toggler.classList.toggle("contact__activate");
    //Radio button off property
    const other1 = document.querySelector(".contact__form");
    const other2 = document.querySelector(".mbGetLoc");
    other1.classList.remove("contact__activate");
    other2.classList.remove("contact__activate");
  };
  const schedule = () => {
    const toggler = document.querySelector(".contact__form");
    toggler.classList.toggle("contact__activate");
    //Radio button off property
    const other1 = document.querySelector(".mb__button__toggle");
    const other2 = document.querySelector(".mbGetLoc");
    other1.classList.remove("contact__activate");
    other2.classList.remove("contact__activate");
  };
  const getMbLocation = () => {
    const toggler = document.querySelector(".mbGetLoc");
    toggler.classList.toggle("contact__activate");
    //Radio button off property
    const other1 = document.querySelector(".mb__button__toggle");
    const other2 = document.querySelector(".contact__form");
    other1.classList.remove("contact__activate");
    other2.classList.remove("contact__activate");
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          animation: "animate__service ease-in-out 0.5s",
        }}
      >
        <h1 className="contact__title">CONTACT US</h1>
        <NavLink className="crossImg" to="/">
          <img
            style={{ height: "20px", marginRight: "2rem" }}
            className="img-fluid"
            src={backLogo}
            alt=""
          />
        </NavLink>
      </div>
      <div
        style={{
          overflowY: "scroll",
          height: "90vh",
          animation: "animate__service ease-in-out 0.5s",
        }}
      >
        <div className="contact__buttons ">
          <button
            className="mb__button"
            style={select === "0" ? activeStyle : {}}
            onClick={() => {
              buttonToggle();
              setSelected("0");
            }}
          >
            ENQUIRE NOW
          </button>
          <div className="mb__button__toggle">
            <a href="tel:+919501446674">
              <i className="fas fa-2x fa-phone"></i>
            </a>
            <a href="mailto:maheep@thegraphicsocial.com">
              <i className="far fa-2x fa-envelope"></i>
            </a>
            <a href="https://wa.me/+919501446674">
              <i className="fab fa-2x fa-whatsapp"></i>
            </a>
          </div>
          <button
            className="mb__button"
            style={select === "1" ? activeStyle : {}}
            onClick={() => {
              schedule();
              setSelected("1");
            }}
          >
            SCHEDULE A CALL
          </button>
          <form action="" className="contact__form">
            <input id="fname" type="text" placeholder="First Name" />
            <input id="lname" type="text" placeholder="Last Name" />
            <ThemeProvider theme={defaultMaterialTheme}>
              <DateTimePicker
                className="datePicker"
                name="dateTime"
                value={date}
                onChange={setDate}
                disablePast
                inputVariant="outlined"
              />
            </ThemeProvider>
            {/* <input
              // style={{
              //   display: "block",
              //   webkitAppearance: "textfield",
              //   mozAppearance: "textfield",
              //   appearance: "textfield",
              // }}
              id="meeting"
              type="date"
              // value={"2000-01-01"}
            />
            <input
              style={
                {
                  // webkitAppearance: "none",
                  // mozAppearance: "none",
                  // appearance: "none",
                }
              }
              id="meeting"
              type="time"
              // value={"08:00:00"}
            /> */}
            <button className="btn btn-outline-light" type="submit">
              Submit
            </button>
          </form>
          <button
            style={select === "2" ? activeStyle : {}}
            onClick={() => {
              getMbLocation();
              setSelected("2");
            }}
            className="mb__button"
          >
            GET DIRECTIONS
          </button>
          <iframe
            title="map"
            className="mbGetLoc"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d212.3415232102146!2d74.85940156127025!3d31.621133611054443!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39197b53dfebf8b5%3A0xcfceb25f0cf9e679!2sBala%20Ji%20Refrigeration%20Works!5e0!3m2!1sen!2sin!4v1637601579274!5m2!1sen!2sin"
            style={{}}
            allowfullscreen=""
            loading="lazy"
          ></iframe>
        </div>
        <div className="contact__content">
          <h3>Address</h3>
          <a
            target="__blank"
            href="https://www.google.com/maps/place/Bala+Ji+Refrigeration+Works/@31.621764,74.866744,19z/data=!4m5!3m4!1s0x0:0xcfceb25f0cf9e679!8m2!3d31.6217637!4d74.8667439?hl=en"
          >
            Amritsar, Punjab, IN
          </a>
          <h3>Phone</h3>
          <a href="tel:+919501446674">+91-95014-46674</a>
          <h3>Mail</h3>
          <a href="mailto:maheep@thegraphicsocial.com">
            maheep@thegraphicsocial.com
          </a>
        </div>
      </div>
    </>
  );
}

export default MbContact;
